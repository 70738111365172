<template>
  <component
    :is="blok.sectionName ? 'section' : 'div'"
    :id="sectionId"
    v-editable="blok"
    :aria-label="blok.sectionName"
    class="component-grid relative !z-0 w-full flex-grow px-4 [&_.component-grid]:!px-0 [&_.component-grid]:![position:unset]"
    :class="[
      {
        'bg-green dark:bg-blue/50': blok.background === 'solid',
        'bg-green-lighter dark:bg-blue': blok.background === 'solid-light',
        'bg-green': blok.background === 'primary',
        'bg-blue-dark': blok.background === 'secondary-dark',
        'bg-white': blok.background === 'white',
        'semi-transparent bg-blue/10 dark:bg-green-lighter/10': blok.background === 'semi-transparent',
        'waves': blok.background === 'waves',
        'with-bg-circle': !!blok.circlePos,
        '!z-10': blok.body?.some(el => el.component.includes('form')),
      },
      blok.gaps === 'none'
        ? 'py-0'
        : blok.gaps === 'tight'
          ? 'py-8 tablet:py-12'
          : blok.gaps === 'snug'
            ? 'py-2 tablet:py-4'
            : 'py-12 tablet:py-16',
    ]"
    :style="`--no-of-items:${blok.body?.length || 0};--max-items:1;--circle-pos-x:${circlePosX};--circle-pos-y:${circlePosY};`"
  >
    <div
      class="component-grid-container container isolate mx-auto grid place-items-start [&_.component-grid-container]:![isolation:unset]"
      :style="`grid-template-columns: repeat(min(calc(var(--no-of-items) + ${blok.layout === 'small-large' ? 1 : 0}), var(--max-items)), minmax(0, 1fr));`"
      :class="[
        {
          'tablet:[--max-items:2]': blok.layout === 'columns',
          'desktop:[--max-items:5]': blok.layout === 'columns' && blok.maxWidth === 'extended',
          'desktop:[--max-items:4]': blok.layout === 'columns' && blok.maxWidth !== 'extended',
          'tablet:[--max-items:2] desktop:[--max-items:3]': blok.layout === 'grid' || blok.layout === 'small-large',
          'tablet:[--max-items:2] desktop:[--max-items:2]': blok.layout === 'two-columns',
          'container-ext': blok.maxWidth === 'extended',
          'desktop:max-w-[55rem]': blok.maxWidth === 'small',
        },
        blok.gaps === 'none'
          ? 'gap-8 gap-y-0'
          : blok.gaps === 'tight'
            ? 'gap-8 tablet:gap-y-10'
            : blok.gaps === 'snug'
              ? 'gap-8 tablet:gap-y-10'
              : 'gap-x-12 gap-y-16 tablet:gap-y-24 desktop:gap-y-[6.5rem]',
      ]"
    >
      <template v-for="(childBlok, i) in blok.body" :key="childBlok._uid">
        <storyblok-component
          :blok="childBlok"
          :class="{ 'reversed-cell': blok.reverse, 'desktop:col-span-2': blok.layout === 'small-large' && i % 2 }"
          :style="`--index: ${i}`"
        />
        <template v-if="blok.gapConnectors">
          <svg
            v-if="blok.gapConnectors === 'arrows' && childBlok.component !== 'spacer'"
            viewBox="0 0 430 40"
            stroke-linejoin="round"
            role="presentation"
            class="mx-auto w-full max-w-screen-tablet fill-none stroke-current stroke-[5px] text-back last-of-type:hidden tablet:stroke-[4px]"
            :class="
              blok.gaps === 'none'
                ? ''
                : blok.gaps === 'tight'
                  ? '-mb-4 tablet:-mb-6'
                  : blok.gaps === 'snug'
                    ? '-mb-4 tablet:-mb-5'
                    : '-mb-8 tablet:-mb-12'
            "
          >
            <path d="M4 4L424 4L215 36Z" />
          </svg>
          <svg
            v-else-if="blok.gapConnectors === 'curves' && childBlok.component !== 'spacer'"
            viewBox="0 0 100 50"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            role="presentation"
            class="mx-auto w-3/4 stroke-current last-of-type:hidden tablet:w-1/3"
            :class="[
              { '-scale-x-100': !(i % 2) },
              blok.gaps === 'none'
                ? ''
                : blok.gaps === 'tight'
                  ? '-my-6 tablet:-my-9'
                  : blok.gaps === 'snug'
                    ? '-my-6 tablet:-my-[1.875rem]'
                    : 'tablet:-my-18 -my-12 desktop:-my-[4.875rem]',
            ]"
          >
            <path d="M 2 2 C2 45,98 5,98 48" />
          </svg>
        </template>
      </template>
    </div>
    <span v-if="isEditor && sectionId" class="absolute left-4 top-2 hidden max-w-60 truncate text-sm opacity-50 tablet:inline-block">
      #{{ sectionId }}
    </span>
  </component>
</template>

<script setup lang="ts">
import type { GridStoryblok } from "~/storyblok-component-types"
import { isEditorKey } from "~/injection-symbols"

defineSlots<{ default(): any; }>()
const props = defineProps<{ blok: GridStoryblok; }>()
const isEditor = inject(isEditorKey, false)

const circlePosX = computed(() =>
  props.blok.circlePos?.includes("left") ? "20%" : props.blok.circlePos?.includes("right") ? "80%" : "50%",
)
const circlePosY = computed(() =>
  props.blok.circlePos?.includes("top") ? "10%" : props.blok.circlePos?.includes("bottom") ? "90%" : "50%",
)

const sectionId = computed(() =>
  props.blok.sectionName
    ?.toLowerCase()
    .replaceAll("ä", "ae")
    .replaceAll("ö", "oe")
    .replaceAll("ü", "ue")
    .replaceAll("ß", "ss")
    .replaceAll(/[^a-z1-9-+ ]/g, "")
    .replaceAll(" ", "-"),
)
</script>

<style scoped lang="scss">
.with-bg-circle {
  --bg: theme("colors.green.lighter");
  --mod-bg: #c0ebff;
  overflow: hidden;

  .bg-green {
    --bg: theme("colors.green.DEFAULT");
    --mod-bg: #b8e9f0;
  }

  .bg-green-lighter {
    --bg: theme("colors.green.lighter");
    --mod-bg: #c0ebff;
  }

  .bg-blue-dark {
    --bg: theme("colors.blue.dark");
    --mod-bg: rgba(70, 89, 110, 0.6);
  }

  @media (prefers-color-scheme: dark) {
    --bg: theme("colors.blue.DEFAULT");
    --mod-bg: rgba(70, 89, 110, 0.6);

    .dark\:bg-blue\/50 {
      --bg: rgb(70 89 110 / 0.5);
      --mod-bg: rgba(70, 89, 110, 0.6);
    }
    .dark\:bg-blue {
      --bg: theme("colors.blue.DEFAULT");
      --mod-bg: rgba(8, 33, 61, 0.2);
    }
  }

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    width: 100dvw;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);
    content: "";
    inset: 50%;

    @screen desktop {
      width: 100%;
    }
  }

  &::before {
    background: radial-gradient(
      circle at min(max(-10%, calc(100% - (var(--circle-pos-x, 20%) * 1.3))), 110%)
        min(max(-10%, calc(100% - (var(--circle-pos-y, 20%) * 2))), 110%),
      var(--mod-bg, transparent) 0%,
      transparent max(40%, 18rem)
    );
  }

  &::after {
    background: radial-gradient(
      circle at var(--circle-pos-x, 20%) var(--circle-pos-y, 20%),
      var(--bg, transparent) 0%,
      transparent max(45%, 24rem)
    );
  }
}

.waves {
  @apply -mt-12 pb-24 pt-40 tablet:pb-28 tablet:pt-32 desktop:pb-40 desktop:pt-40;
  @apply before:absolute before:left-0  before:top-0 before:-z-10  before:h-[47dvw] before:w-full before:bg-[url('/wave.svg')]      before:bg-repeat-x before:content-[''] tablet:before:h-[23.5dvw]      desktop:before:h-[23.5dvw] dark:before:bg-[url('/wave-dark.svg')];
  @apply after:absolute  after:bottom-0 after:left-0 after:-z-10   after:h-[47dvw]  after:w-full  after:rotate-180 after:bg-[url('/wave-lines.svg')]  after:bg-repeat-x  after:content-[''] tablet:after:h-[23.5dvw]  desktop:after:h-[23.5dvw] dark:after:bg-[url('/wave-lines-dark.svg')];

  &::before,
  &::after {
    background-position-x: 50%;
    background-size: 50% 100%;

    @screen phone {
      background-size: 100% 100%;
    }
  }
}

.reversed-cell {
  order: calc(var(--index) * -1);

  @screen tablet {
    order: unset;
  }
}
</style>
